import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import i18n from "@/i18n";

import { debug } from "@/misc/debug";
import Presentation from "@/views/initial-flow/Presentation";
import Slides from "@/views/initial-flow/Slides";
import Downloads from "@/views/side-menu/Downloads";
import Options from "@/views/Options";
import Keyboard from "@/views/Keyboard";
import Sponsors from "@/views/Sponsors";
import NotFound from "@/views/NotFound";
import * as mutationTypes from "@/store/mutation-types";
import idb from "../api/base/idb";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history', //this needs server configuration to redirect every url to "/index.html" file
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'NotFound',
			component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
		},
		{
			path: '/museums/:idMuseum',
			component: () => import(/* webpackChunkName: "presentation" */ '../views/HomeView'),
			children: [
				{
					path: '',
					name: 'Presentation',
					component: () => import(/* webpackChunkName: "presentation" */ '../views/initial-flow/Presentation')
				},
				{
					path: 'code',
					name: 'Code',
					component: () => import(/* webpackChunkName: "initial-flow" */ '../views/initial-flow/Code')
				},
				{
					path: 'privacy-policy',
					name: 'PrivacyPolicy',
					component: () => import(/* webpackChunkName: "initial-flow" */ '../views/initial-flow/PrivacyPolicy')
				},
				{
					path: 'login',
					name: 'SocialLogin',
					component: () => import(/* webpackChunkName: "initial-flow" */ '../views/initial-flow/SocialLogin')
				},
				{
					path: 'tutorial',
					name: 'Slides',
					component: Slides
				},
				{
					path: 'download',
					name: 'Download',
					component: () => import(/* webpackChunkName: "initial-flow" */ '../views/initial-flow/Download')
				},
				{
					path: 'options',
					name: 'Options',
					component: Options
				},
				{
					path: 'options/kids',
					name: 'OptionsKids',
					component: () => import(/* webpackChunkName: "kids" */ '../views/modes/kids/OptionsKids')
				},
				{
					path: 'options/by-halls',
					name: 'By Halls - Options',
					component: () => import(/* webpackChunkName: "by-halls" */ '../views/modes/by-halls/Options')
				},
				{
					path: 'keyboard',
					name: 'Keyboard',
					component: Keyboard
				},
				{
					path: 'sponsors/:idResource',
					name: 'Sponsors',
					component: Sponsors
				},
				{
					path: 'artworks',
					name: 'ArtworkView',
					component: () => import(/* webpackChunkName: "artworks" */ '../views/artworks/ArtworkView'),
					children: [
						{
							path: 'kids',
							name: 'ArtworksKids',
							component: () => import(/* webpackChunkName: "kids" */ '../views/modes/kids/ArtworksKids')
						},
						{
							path: ':idArtwork',
							name: 'Artwork',
							component: () => import(/* webpackChunkName: "artworks" */ '../views/artworks/Artwork'),
						},
						{
							path: ':idArtwork/resources/:idResource',
							name: 'Resource',
							component: () => import(/* webpackChunkName: "artworks" */ '../views/artworks/Resource')
						},
					]
				},
				{
					path: 'exhibitions',
					name: 'ExhibitionList',
					component: () => import(/* webpackChunkName: "routes" */ '../views/routes/RouteList'),
				},
				{
					path: 'exhibitions/:idRoute',
					name: 'Exhibition',
					component: () => import(/* webpackChunkName: "routes" */ '../views/routes/Route'),
				},
				{
					path: 'routes',
					name: 'RouteList',
					component: () => import(/* webpackChunkName: "routes" */ '../views/routes/RouteList'),
				},
				{
					path: 'routes/:idRoute',
					name: 'Route',
					component: () => import(/* webpackChunkName: "routes" */ '../views/routes/Route'),
				},
				{
					path: 'timeline',
					name: 'TimelineList',
					component: () => import(/* webpackChunkName: "timeline" */ '../views/timeline/TimelineList')
				},
				{
					path: 'timeline/:idTimeline',
					name: 'Timeline',
					component: () => import(/* webpackChunkName: "timeline" */ '../views/timeline/Timeline')
				},
				{
					path: 'one-minutes',
					name: 'OneMinuteList',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/OneMinute/OneMinuteList')
				},
				{
					path: 'one-minutes/:idOneMinute',
					name: 'OneMinute',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/OneMinute/OneMinute')
				},
				{
					path: 'info-sections',
					name: 'InfoSectionList',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/InfoSections/InfoSectionList')
				},
				{
					path: 'info-sections/:idInfoSection',
					name: 'InfoSection',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/InfoSections/InfoSection')
				},
				{
					path: 'outstanding-artworks',
					name: 'OutstandingArtworks',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/OutstandingArtworks')
				},
				{
					path: 'languages',
					name: 'Languages',
					component: () => import(/* webpackChunkName: "languages" */ '../views/side-menu/Languages')
				},
				{
					path: 'recognition',
					name: 'Recognition',
					component: () => import(/* webpackChunkName: "recognition" */ '../views/Recognition')
				},
				{
					path: 'hall/:idHall/by-halls',
					name: 'By Halls - Hall',
					component: () => import(/* webpackChunkName: "by-halls" */ '../views/modes/by-halls/Hall'),
				},
				{
					path: 'hall/:idHall/artworks/:idArtwork/by-halls',
					name: 'By Halls - Artwork',
					component: () => import(/* webpackChunkName: "by-halls" */ '../views/modes/by-halls/Artwork'),
				},
				{
					path: 'hall/:idHall',
					name: 'Hall',
					component: () => import(/* webpackChunkName: "beacons" */ '../views/beacons/Hall'),
				},
				{
					path: 'beacon/:idBeacon',
					name: 'Beacon',
					component: () => import(/* webpackChunkName: "beacons" */ '../views/beacons/Beacon')
				},
				{
					path: 'guide',
					name: 'GuidedVisit',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/GuidedVisit')
				},
				{
					path: 'downloads',
					name: 'Downloads',
					component: Downloads
				},
				{
					path: 'game',
					name: 'Game',
					component: () => import(/* webpackChunkName: "kids" */ '../views/modes/kids/Game')
				},
				{
					path: 'study',
					component: () => import(/* webpackChunkName: "study" */ '../views/study/Study'),
					children: [
						{
							path: '',
							name: 'StudyQuestion',
							component: () => import(/* webpackChunkName: "study" */ '../views/study/StudyQuestion')
						},
						{
							path: ':idArtwork',
							name: 'StudyQuestionArtwork',
							component: () => import(/* webpackChunkName: "study" */ '../views/study/StudyQuestion'),
						},
					]
				},
				{
					path: 'sections',
					name: 'Sections',
					component: () => import(/* webpackChunkName: "kids" */ '../views/modes/kids/Sections')
				},
				{
					path: 'goodbye',
					name: 'Reset',
					component: () => import(/* webpackChunkName: "side-menu" */ '../views/side-menu/Reset')
				},
				{   // This should be the last route to be checked for the app to check the mode not colliding
					// with /tutorial, /code , /options or whatever
					path: ':mode',
					name: 'Presentation',
					component: Presentation,
				},
			]
		},

		{ path: '*', redirect: '/', component: NotFound }
	]
});

router.beforeEach(async (to, from, next) => {
	debug.open("Routing checks before redirecting");
	store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
	const hasPreselectedDistinctLanguage = localStorage.getItem('hasPreselectedLanguage');
	const hasRoute = await idb.getAllFromDB('routes') ? await idb.getAllFromDB('routes') : [];
	//Decipher the id of the museum
	let idMuseum = to.params.idMuseum;
	try {
		let encrypted = (atob(idMuseum));
		idMuseum = encrypted.split('-')[0];
	} catch (e) {
		if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
	}

	const isPWA = () => {
		const isStandaloneIOS = window.navigator.standalone === true;
		const isStandaloneOther = window.matchMedia('(display-mode: standalone)').matches;
		return isStandaloneIOS || isStandaloneOther;
	  };

	//Check if the museum is the one being used or has been changed
	let currentMuseum = localStorage.getItem('current-museum');

	if(!isPWA()){
		if (currentMuseum !== idMuseum) {
			debug.log("The current museum is not the one being loaded, resetting it...");
			await store.dispatch('app/resetMuseum');
		}
	}
	
	//Check if there is a preselected language different from the device one
	if (to.query?.lang) {
		i18n.locale = to.query.lang;
		debug.log("Changed language to " + to.query.lang);
		localStorage.setItem('language', i18n.locale);
	}

	//Check if there is a special mode being applied (i.e. kids mode)
	if (to.params.mode) {
		debug.log("Setting the webapp mode to " + to.params.mode);
		localStorage.setItem('mode', to.params.mode);
	} else if (to.name === 'Presentation') {
		debug.log("Resetting the webapp to normal mode");
		localStorage.removeItem('mode');
	}
	let mode = localStorage.getItem('mode');

	let viewName = null;
	const PUBLIC_VIEWS = ['NotFound', 'Presentation', 'PrivacyPolicy'];
	if (!PUBLIC_VIEWS.includes(to.name) && store?.state?.app) {
		const downloadEmptyState = await idb.getFromDB(1, 'downloadEmpty');

		if (!store.state.museum?.museum &&  downloadEmptyState?.status !== false)
			await store.dispatch('museum/fetchMuseumDetail', idMuseum);

		let code = store.state.museum?.museum?.accessCodeRequired ? store.state.app.code : true;
		debug.log("Code is" + (store.state.museum?.museum?.accessCodeRequired ? "" : " not") + " required");
		debug.log("Current saved code is " + store.state.app.code);
		let codeCreationDate = localStorage.getItem('code-creation-date');
		let codeDaysUntilExpiration = localStorage.getItem('code-days-until-expiration');
		if (codeCreationDate) {
			let millisecondsInAnHour = 1000 * 60 * 60;
			let hoursSinceCreation = (new Date() - new Date(codeCreationDate)) / millisecondsInAnHour;
			if (hoursSinceCreation >= ((codeDaysUntilExpiration || 1) * 24)) {
				debug.log("The code has expired (was introduced " + hoursSinceCreation + " hours ago and the code was valid for " + (codeDaysUntilExpiration * 24) + " days)");
				localStorage.removeItem('code');
				localStorage.removeItem('code-creation-date');
				localStorage.removeItem('code-days-until-expiration');
				await store.commit('app/' + mutationTypes.SET_CODE, null);
				code = null;
			}
		}
		let user = store.state.museum?.museum?.socialNetworksRequired ? store.state.app.user : true;

		debug.open("Check study questions");
		let hasStudy = false;
		let startStudiesMade = localStorage.getItem('start-studies-made');
		debug.log("Study questions have" + (startStudiesMade ? "" : "not") + " been seen");
		if (!startStudiesMade) {
			//Checks for initial study
			if (store.state.museum?.museum?.studies?.length) {
				debug.log("Checking study questions at the start of the visit");
				hasStudy = store.state.museum.museum.studies.some((study) => {
					if (['EASYGUIDE', 'BOTH'].includes(study.showOn)) {
						return study.questions.find(q => !q.idArtwork) != null;
					}
					return false;
				});
				debug.log(hasStudy ? "There are study questions to be made" : "There are no study questions at the start of the visit");
			} else {
				debug.log("There are no study questions at the start of the visit");
			}
		}
		debug.close();
		let slidesSeen = localStorage.getItem('slides-seen');		
		const forceDownloadAll = (localStorage.getItem('forceDownloadAll') === 'true');
		const downloadsEmpty = store.state.museum?.downloads === null;

		if (!code) {
			localStorage.removeItem('code');
			viewName = 'Code';
		} else if (!user) {
			viewName = 'SocialLogin';
		} else if (hasPreselectedDistinctLanguage === true && forceDownloadAll && downloadsEmpty) {
			if(to.name === 'Downloads') {
				viewName = 'Downloads';
			} else {
				viewName = 'Languages';
			}
		} else if (hasStudy && !startStudiesMade) {
			viewName = 'StudyQuestion';
		} else if (slidesSeen === 'true') {
			viewName = 'Slides'; // Redirige si el usuario no ha visto las diapositivas
		} else if (to.name === 'Download') {
			viewName = 'Download';
		} 
	}

	//TODO
	// The download section has UX problems and users were confused about its usage. Until fix, we must skip it
	if (viewName === 'Download' || to.name === 'Download' ||(hasPreselectedDistinctLanguage === false && hasRoute.length > 0) ) {
		viewName = 'Options';
	}
	// Kids redirections
	if (to.name === 'Options') {
		if (mode === 'kids') {
			viewName = 'OptionsKids';
		} else if (mode === 'by-halls') {
			viewName = 'By Halls - Options';
		}
	}

	store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
	debug.close();
	if (viewName && viewName !== to.name) {
		next({ name: viewName, params: { idMuseum: to.params.idMuseum } });
	} else {
		next();
	}
	next();
});

export default router;
