<template>
	<div class="content" :style="cssVars">
		<vue-headful :title="pageTitle" />
		<div v-if="isLoading">
			<Load />
		</div>


		<main v-if="!isLoading">
			<div v-if="!museumFetchingError && museum.logoImageUrl" class="logo">
				<img width="640" height="360" class="logo-image"
					:src="museum.logoImageUrl ?? dataMuseumIDB.logoImageUrl" alt="">
			</div>
		</main>

		<span v-if="mode" class="mode">{{ mode }}</span>
		<span v-if="localVersion" class="version">Local: v{{ localVersion }} - Last: v{{ lastVersion }}</span>
		<!-- <button v-if="localVersion" :style="cssbutton" id="downloadButton" class="download-button" @click="install()">
          <i class="mm-icon mm-icon-download"></i>Download
        </button> -->


		<span v-if="!isLoading && (museum.privacyPolicyEnabled ?? dataMuseumIDB.privacyPolicyEnabled)"
			class="privacy-policy-msg" v-html="$t('privacy_policy_accept').replace('%1$d', $t('privacy_policy'))" />
		<button v-show="!museumFetchingError" class="button" @click="startVisit()">
			{{ $t("start_visit") }}
		</button>
		<button v-show="!isLoading && museumFetchingError" class="button error">{{
			$t("error_museum_deactivated") }}</button>
	</div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapState, mapActions } from 'vuex';
// import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { setFullScreen } from "@/store/helpers";
import compareDatesToday from "../../utils/compareDatesToday";
import MuseumsAPI from "@/api/MuseumsAPI";
import Load from '../../../src/components/Load.vue';
import idb from "../../api/base/idb";

export default {
	name: "Presentation",

	components: {
		// Loading
		Load,
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			localVersion: localStorage.getItem('version'),
			lastVersion: process.env.VUE_APP_VERSION,
			mode: '',
			codeQueryParam: this.$route.query.code,
			isDownloadEmptyIDB: { status: true },
			imageBlob: null,
			customizationPresentation: null
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2,
			customization: state => state.museum.customization,
			savingResource: state => state.museum.savingResource,
			museumFetchingError: state => state.museum.museumFetchingError,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			codeNeeded: state => state.museum.museum?.accessCodeRequired || false
		}),

		cssVars() {
			return {
				
				'--bg-image': 'url(' + ((this.museum && this.museum.hasOwnProperty('homeImageUrl')) ? this.museum.homeImageUrl : this.imageBlob) + ')',
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : this.customizationPresentation.mainButtonsColor ,
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : this.customizationPresentation.mainButtonsTextColor,
			};
		},
		cssbutton() {
			return {
				"background-color": this.customization
					?.mainButtonsCustomizationEnabled
					? this.customization.mainButtonsColor
					: "#72c4d0",
				"color": this.customization
					?.mainButtonsCustomizationEnabled
					? this.customization.mainButtonsTextColor
					: "#ffffff",
			};
		},


		pageTitle() {
			let museumName = (this.museum && this.museum.name)
				? this.museum.name + ' | '
				: (this.museum && this.museum.locale && this.museum.locale.name)
					? this.museum.locale.name + ' | '
					: "";

			return museumName + 'MuseumMate';
		}
	},


	mounted() {
		const isAppInstalled = localStorage.getItem('isAppInstalled');
		if (isAppInstalled) {
			this.hideDownloadButton();
		}
		if (!this.isNetworkOnline) {
			this.haveUrl().then(() => {
				console.debug('resuelto');
			});
		}
	},
	created() {

		debug.log("[Presentation] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		window.addEventListener("beforeinstallprompt", (e) => {
			this.$store.commit('app/' + mutationTypes.SET_DEFERRED_PROMPT, e);
			e.preventDefault();
			this.deferredPrompt = e;
		});
		window.addEventListener("appinstalled", () => {
			this.deferredPrompt = null;
			localStorage.setItem('isAppInstalled', true);
			this.hideDownloadButton();
		});

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];

			this.mode = localStorage.getItem('mode');
		} catch (e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
		this.handleMuseumPresentation();
		this.getDataMuseumIdb();
		this.imageBlob = this.loadBlobImage();
		this.customizationPresentation = this.loadColorCustomization();
	},

	methods: {
		...mapActions('app', ['loadMuseum', 'loadMuseumPresentation', 'resetMuseum', 'fillDeferredPrompt']),
		...mapActions('museum', ['fetchMuseumDetail', 'getMuseumFromDB', 'storeInIDB']),

		async handleMuseumPresentation() {
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			try {
				await this.loadMuseumPresentation(this.idMuseum);
				const museumData = this.$store.state.museum.museum;
				//Aqui falta una validacion porque cuando esta online aun y ya esta descargado lo vuelve a descargar
				if (this.isNetworkOnline && this.isDownloadEmptyIDB.status !==false) { 
					await this.storeInIDB({
						storeName: "museumsPresentation",
						element: museumData 
					});
				}
			} catch (error) {
				console.error("Error loading museum presentation:", error);

				try {
					const museum = await idb.getFromDB(parseInt(this.idMuseum), 'museumsPresentation');
					if (museum) {
						this.$store.commit('museum/' + mutationTypes.SET_MUSEUM, museum);
					} else {
						console.error("No valid museum data found in IndexedDB.");
					}
				} catch (dbError) {
					console.error("Error retrieving data from IndexedDB:", dbError);
				}
			} finally {
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			}
		},

		async DownloadEmpty() {
			const downloadData = {
				idDownloadEmpty: 1,
				status: true,
				countDownload: 1,
			};
			idb.saveToDB(downloadData, "downloadEmpty");
		},

		languageLocalStorage(languagesV2) {
			let languagesLS = JSON.parse(languagesV2);
			if (!localStorage.getItem('language') && this.isNetworkOnline) {
				if (languagesLS.length > 0) {
					const firstLanguage = languagesLS[0];
					const shortCode = firstLanguage.code.split('_')[0];
					localStorage.setItem('language', shortCode);
				} else {
					console.warn("El array de idiomas está vacío. No se puede establecer 'language'.");
				}
			} else {
				console.debug("'language' ya existe en LS con valor:", localStorage.getItem('language'));
			}
		},

		async startVisit() {
			setFullScreen();
			try {
				const downloadEmptyState = await idb.getFromDB(1, 'downloadEmpty');
				this.isDownloadEmptyIDB = downloadEmptyState;
				if (downloadEmptyState) {
					console.debug("downloadEmptyState en Presentacion: ", downloadEmptyState);
				}
			} catch (error) {
				console.error("Error al obtener downloadEmptyState:", error);
				this.DownloadEmpty();
			}

			// el registro de la visita deberia ir cuando ya selecciona un idioma
			const startDateCondition = JSON.parse(localStorage.getItem("visitv2"))?.startDate;
			let languagesV2 = localStorage.getItem('languagesV2');
			if (this.isNetworkOnline) {
				this.languageLocalStorage(languagesV2);
			}

			if (languagesV2) {
				languagesV2 = JSON.parse(languagesV2);
			} else {
				if (this.isNetworkOnline) {
					languagesV2 = await MuseumsAPI.getLanguagesByMuseum(+this.visit.idMuseum);
				}
			}

			if (compareDatesToday(startDateCondition)) {
				const languageId = languagesV2.find(e => e.code === this.visit.languageCode)?.id || languagesV2[0].id;
				const languageCode = languagesV2.find(e => e.code === this.visit.languageCode)?.code || languagesV2[0].code;
				if (this.isNetworkOnline) {
					MuseumsAPI.registerVisitMuseumV2(+this.visit.idMuseum, languageId, languageCode, this.visit.idDevice, mutationTypes.SET_VISITV2, this.$store);
				}
			}

			debug.open("Checking version");
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			let version = localStorage.getItem('version');
			debug.log("localVersion: " + version + " - lastVersion: " + process.env.VUE_APP_VERSION);
			
			if (!version || version && version !== process.env.VUE_APP_VERSION) {
				debug.log("Version is different (" + version + ") from last version (" + process.env.VUE_APP_VERSION + "), resetting museum");
				await this.resetMuseum();
				localStorage.setItem('current-museum', this.idMuseum);
			}

			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			if (this.codeNeeded && this.codeQueryParam) {
				await this.$router.push({ name: 'Code', params: { idMuseum: this.encrypted }, query: { code: this.codeQueryParam } });
			} else if (this.codeNeeded) {
				await this.$router.push({ name: 'Code', params: { idMuseum: this.encrypted } });
			}
			await this.$router.push({ name: 'Languages', params: { idMuseum: this.encrypted } });
		},
		async install() {
			if (this.deferredPrompt) {
				try {
					await this.deferredPrompt.prompt();
				} catch (error) {
					console.error("An error occurred while calling the prompt:", error);
				}
			}
		},

		hideDownloadButton() {
			const downloadButton = document.getElementById('downloadButton');
			if (downloadButton) {
				downloadButton.style.display = 'none';
			}
		},

		async haveUrl() {
			this.urlObject = await idb.getFromDB(1, 'url') ? await idb.getFromDB(1, 'url') : null;
			const regex = /\/museums\/[^/]+/;
			const match = this.urlObject.url.match(regex);
			const desiredPart = match ? match[0] : null;

			if (this.urlObject && this.urlObject.url) {
				this.$router.push(desiredPart);
			}
		},

		async getblobImage() {
			let indexDbResult;
			try {
				if (this.idMuseum !== null) {
					indexDbResult = await idb.getFromDB(parseInt(this.idMuseum), 'museumsPresentation');
				}
			}
			catch (e) {
				console.error('An error has occurred', e);
			}
			if (indexDbResult) {
				return indexDbResult.homeImageUrl;
			}
		},

		getImageUrl(url) {
			if (url instanceof Blob) {
				return URL.createObjectURL(url);
			}

			return url;
		},

		async getDataMuseumIdb() {
			let dataMuseumIDB;
			try {
				if (this.idMuseum !== null) {
					dataMuseumIDB = await idb.getFromDB(parseInt(this.idMuseum), "museumsPresentation");
				} else {
					console.error("Id Museuo no disponible");
				}

				if (dataMuseumIDB) {
					return dataMuseumIDB;
				} else {
					console.error("hubi un error al obtener la data del museo de IDB");
				}
			} catch (error) {
				console.error("error al obtener la data del museuo de IDB", error);
			}
		},

		async getDataCustomizationIdb() {
			let dataCustomizationIDB;
			try {
				if (this.idMuseum !== null) {
					dataCustomizationIDB = await idb.getFromDB(parseInt(this.idMuseum), "customizationsPresentation");
				} else {
					console.error("Id Museuo no disponible");
				}

				if (dataCustomizationIDB) {
					return dataCustomizationIDB;
				} else {
					console.error("hubi un error al obtener la data del museo de IDB");
				}
			} catch (error) {
				console.error("error al obtener la data del museuo de IDB", error);
			}
		},

		async loadBlobImage() {
			try {
				const blob = await this.getblobImage();
				this.imageBlob = this.getImageUrl(blob);
			} catch (error) {
				console.error("Error loading blob image:", error);
			}
		},

		async loadColorCustomization() {
			try {
				const loadColorCustomizationIDB = await this.getDataCustomizationIdb();
				this.customizationPresentation = loadColorCustomizationIDB;
				return loadColorCustomizationIDB;
			} catch (error) {
				console.error("Error loading customization:", error);
			}
		}

	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;

	main {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: var(--bg-image);
		background-color: white;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: auto;
			padding-top: 75px;

			.logo-image {
				width: 70%;
				height: auto;
			}
		}
	}

	.mode {
		position: absolute;
		top: 5px;
		left: 5px;
		color: rgba(255, 255, 255, 0.35);
		font-size: 12px;
	}

	.version {
		position: absolute;
		top: 5px;
		right: 5px;
		color: rgba(255, 255, 255, 0.35);
		font-size: 12px;
	}

	.privacy-policy-msg {
		position: absolute;
		bottom: 79px;
		padding-bottom: 10px;
		text-align: center;
		width: 100%;
		color: rgba(white, 0.8);
		font-size: 12px;
		background: linear-gradient(transparent 10%, rgba(0, 0, 0, 0.3));
	}

	.button {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 30px;
		border: none;
		background-color: var(--main-buttons-color);
		color: var(--main-buttons-text-color);
		text-transform: uppercase;
	}

	.download-button {
		background-color: rgb(20, 249, 100);
		/* Color verde */

		padding: 10px 20px;
		font-size: 16px;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		transition: background-color 0.3s ease;
		position: absolute;
		top: 5px;
		right: 235px;
		color: black;
		font-size: 12px;
	}

	.download-button:hover {
		background-color: #45a049;
	}
}
</style>
