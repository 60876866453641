import { debug } from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { APIFactory } from "@/api/APIFactory";
import idb from "@/api/base/idb";
import helper from "@/api/base/helper";
import { findLocalization } from "@/store/helpers";
import { museumResources } from "@/store/helpers";
import i18n from "@/i18n";
import Visit from "@/models/visit";
import router from '@/router';
import Downloads from "@/models/downloads";
import { createNewVisit } from "../helpers";
import compareDatesToday from "../../utils/compareDatesToday";

const MuseumsAPI = APIFactory.get('museums');
const CustomizationsAPI = APIFactory.get('customizations');

const state = {
	/**
	 * Object representation of the museum
	 * @type {Object}
	 */
	museum: null,

	/**
	 * Object representation of the museum customization
	 * @type {Object}
	 */
	customization: null,

	/**
	 * Object representation of the museum side menu
	 * @type {Object}
	 */
	sideMenu: null,

	/**
	 * Will be true when there is an error fetching a museum
	 * @type {boolean}
	 */
	museumFetchingError: false,

	/**
	 * Indicates the resource that is being saved
	 * @type {String}
	 */
	savingResource: i18n.t('fetching_api'),

	/**
	 * Indicates the number of resources that have been already saved
	 * @type {number}
	 */
	savedResources: 0,

	/**
	 * Indicates the total number of resource types that have to be downloaded
	 * @type {number}
	 */
	totalResources: 0,

	/**
	 * Object representation of the museum downloads
	 * @type {Downloads}
	 */
	downloads: null,

	/**
	 * Indicates the total number of resource types that have to be downloaded
	 * @type {number}
	 */
	downloadsError: false,
};

const getters = {

};

const actions = {

	/**
	 * Checks if a given museum is already saved in the database
	 * @param store
	 * @param {(number|string)} idMuseum - The id of the museum
	 * @returns {Promise<{}>} The resolve will return the museum, the reject will not return anything
	 */
	getMuseumFromDB: (store, idMuseum) => {
		return new Promise((resolve, reject) => {
			debug.open('getMuseumFromDB');
			debug.log("Started searching for the museum " + idMuseum);
			
			try {
				idb.getFromDB(parseInt(idMuseum))
				.then((result) => {
					try {
						let museum = result;
						if (!result) {
							debug.log(`No data found for museum ${idMuseum} in iDB.`);
							reject(new Error(`Museum ${idMuseum} not found in iDB.`));
							return;
						}	
							if (museum?.googleTagManagerId) {
								try {
									debug.log("Init Google Tag Manager with ID " + museum.googleTagManagerId);
									(function (w, d, s, l, i) {
										w[l] = w[l] || [];
										w[l].push({
											'gtm.start': new Date().getTime(),
											event: 'gtm.js'
										});
										let f = d.getElementsByTagName(s)[0],
											j = d.createElement(s),
											dl = l != 'dataLayer' ? '&l=' + l : '';
										j.async = true;
										j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
										f.parentNode.insertBefore(j, f);
									})(window, document, 'script', 'dataLayer', museum.googleTagManagerId);
								} catch (error) {
									debug.log(`Error initializing Google Tag Manager: ${error.message || error}`);
								}
							}
	
							if (museum && !museum.resolvedResources) {
								helper.propertiesToUrl(museum)
									.then(() => {
										try {
											museum.resolvedResources = true;
											debug.log("Museum " + idMuseum + ' has been got from iDB');
											debug.close();
											resolve(museum);
										} catch (error) {
											debug.log(`Error resolving resources for museum ${idMuseum}: ${error.message || error}`);
											reject(error);
										}
									})
									.catch((error) => {
										debug.log(`Error processing propertiesToUrl: ${error.message || error}`);
										reject(error);
									});
							} else {
								debug.log("Museum " + idMuseum + ' does not exist in iDB');
								debug.close();
								reject(new Error(`Museum ${idMuseum} not found in iDB`));
							}
						} catch (error) {
							debug.log(`Error handling museum result: ${error.message || error}`);
							reject(error);
						}
					})
					.catch((error) => {
						debug.log(`Error fetching museum from iDB: ${error.message || error}`);
						reject(error);
					});
			} catch (error) {
				debug.log(`Unexpected error in getMuseumFromDB: ${error.message || error}`);
				reject(error);
			}
		});
	},
	

	/**
	 * Saves an array of elements to the iDB
	 * @param commit
	 * @param {Array} element Array of elements
	 * @param {string} elementName Name of the element and of the iDB objectStore
	 * @returns {Promise<void>}
	 */
	saveElement: async ({ commit }, { element, elementName }) => {
		debug.open("saveElement " + elementName);
		if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Storage data: ", data));
		commit(mutationTypes.SET_TOTAL_RESOURCES, element.length);
		commit(mutationTypes.SET_SAVED_RESOURCES, 0);
		let start = new Date();

		let translatedProperty = i18n.t(elementName);
		let nameCapitalized = translatedProperty.charAt(0).toUpperCase() + translatedProperty.slice(1);

		let alreadySaved = 0;
		for (let index in element) {
			let translatedResourcesNumber = String(i18n.t('resource_number')).replace('%1$d', String(element.length - alreadySaved));
			let savingResource = nameCapitalized + ': ' + translatedResourcesNumber;
			commit(mutationTypes.SET_SAVING_RESOURCE, savingResource);

			await helper.transform(element[index]);
			await idb.saveToDB(element[index], elementName);

			alreadySaved++;
			commit(mutationTypes.SET_SAVED_RESOURCES, alreadySaved);
		}

		debug.log("Downloaded, transformed and saved in " + (new Date() - start) + "ms");
		debug.close();
	},

	/**
	 * Saves an element to the iDB
	 * @param store
	 * @param {Array} element Element to be saved
	 * @param {string} storeName Name of the iDB objectStore
	 * @returns {Promise<void>}
	 */
	storeInIDB: async (store, { element, storeName }) => {
		try {
			debug.open("storeInIDB " + storeName);
		if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Storage data: ", data));
		let start = new Date();

		await helper.transform(element);
		await idb.saveToDB(element, storeName);

		debug.log("Downloaded, transformed and saved in " + (new Date() - start) + "ms");
		debug.close();
		}
		catch(e){
			debug.log("Error in storeInIDB ",e);
		}
		
	},

	/**
	 * Saves a museum to the iDB
	 * @param store
	 * @param {Object} museum                           - The museum model to be saved
	 * @param {(number|string)} museum.idMuseum         - Identifier of the museum
	 * @param {boolean} museum.active                   - Indicates if the museum is active or not
	 * @param {string} museum.address                   - Address of the museum
	 * @param {Array} museum.artworks                   - All artworks of the museum
	 * @param {Array} museum.beacons                    - Possible beacons of the museum
	 * @param {string} museum.city                      - City location of the museum
	 * @param {string} museum.code                      - Code id of the museum
	 * @param {string} museum.countryIso                - ISO code of the country of the museum
	 * @param {string} museum.googleTagManagerId        - ID of the Google Tag Manager for the museum
	 * @param {Array} museum.halls                      - All halls of the museum
	 * @param {string} museum.homeImageUrl              - Image URL of the museum main picture
	 * @param {Array} museum.infoSections               - All info sections of the museum
	 * @param {Array} museum.languages                  - All languages of the museum
	 * @param {Object} museum.locale                    - Current locale of the user for the museum
	 * @param {Array} museum.localizations              - Available localizations for the museum
	 * @param {string} museum.logoImageUrl              - Image URL of the museum logo
	 * @param {Array} museum.oneMinutes                 - All oneMinutes of the museum
	 * @param {(number|string)} museum.phone            - Phone number of the museum
	 * @param {boolean} museum.menuAvailable            - Indicates if the menu is activated or not
	 * @param {string} museum.postalCode                - Postal code of the museum
	 * @param {string} museum.province                  - Province location of the museum
	 * @param {boolean} museum.recognitionAvailable     - Indicates if the recognition is activated or not
	 * @param {string} museum.resetCode                 - Code used to reset the information of the museum
	 * @param {Array} museum.routes                     - All routes of the museum
	 * @param {Array} museum.sections                   - All sections of the museum
	 * @param {Array} museum.slides                     - All slides of the walkthrough of the museum
	 * @param {Array} museum.sideMenuCustomization      - Customization options of the side menu of the museum
	 * @param {string} museum.state                     - State location of the museum
	 * @param {Array} museum.staticImages               - Static images of the museum
	 * @param {Array} museum.staticTexts                - Static texts of the museum
	 * @param {Array} museum.tags                       - Tags of the museum
	 * @param {Object} museum.timeline                  - Timeline of the museum (if it exists)
	 * @param {string} museum.tourImageUrl              - Image URL of the tour
	 * @param {string} museum.walktroughImageUrl        - Image URL of the walkthrough
	 * @param {Array} download                          - Elements to be re-downloaded
	 * @returns {Promise<void>}
	 */
	save: async ({ dispatch }, { museum, download = null }) => {
		debug.open('museum:save');
		if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Initial storage data: ", data));

		let startIteration = new Date();
		let elementFromIDB = await idb.getFromDB(parseInt(museum.idMuseum));
		if (!elementFromIDB) {
			let { artworks, halls, infoSections, navigationSections, oneMinutes, routes, sections, timeline, ...museumDetails } = museum;

			debug.log("Saving museum details (with no resources)", museumDetails);
			await helper.transform(museumDetails);

			//Adding the "online" resources again so it works online if some of them fails in offline
			museumDetails.artworks = artworks;
			museumDetails.halls = halls;
			museumDetails.infoSections = infoSections;
			museumDetails.navigationSections = navigationSections;
			museumDetails.oneMinutes = oneMinutes;
			museumDetails.routes = routes;
			museumDetails.sections = sections;
			museumDetails.timeline = timeline;
			// museumDetails.sideMenuCustomization	= sideMenuCustomization;
			await idb.saveToDB(museumDetails);

			if (artworks?.length) await dispatch('saveElement', { element: artworks, elementName: 'artworks' });
			if (halls?.length) await dispatch('saveElement', { element: halls, elementName: 'halls' });
			if (infoSections?.length) await dispatch('saveElement', { element: infoSections, elementName: 'infoSections' });
			if (navigationSections?.length) await dispatch('saveElement', { element: navigationSections, elementName: 'navigationSections' });
			if (oneMinutes?.length) await dispatch('saveElement', { element: oneMinutes, elementName: 'oneMinutes' });
			if (routes?.length) await dispatch('saveElement', { element: routes, elementName: 'routes' });
			if (sections?.length) await dispatch('saveElement', { element: sections, elementName: 'sections' });
			if (timeline) await dispatch('saveElement', { element: [timeline], elementName: 'timeline' });
			// if (sideMenuCustomization) await dispatch('saveElement', { element: [sideMenuCustomization], elementName: 'sideMenuCustomization' });

			debug.log("Full museum " + museum.idMuseum + ' saved in ' + (new Date() - startIteration) + 'ms');
			if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Storage data after saving the museum detail: ", data));
		} else if (download) {
			for (let index in download) {
				let resourceName = download[index];
				debug.log("Saving " + resourceName);
				let resource = museum[resourceName];
				await dispatch('saveElement', { element: (resourceName === 'timeline') ? [resource] : resource, elementName: resourceName });
				if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Storage data after saving " + resourceName + ": ", data));
			}
		} else {
			debug.log("Museum " + museum.idMuseum + ' fetched from iDB in ' + (new Date() - startIteration) + 'ms');
		}
		debug.close();
	},

	/**
	 * Gets the full store of given element
	 * @param store
	 * @param elementName
	 * @returns {Array}
	 */
	async getElementsFromDB(store, elementName) {
		debug.open("getElementsFromDB " + elementName);
		debug.log("Started searching " + elementName);

		return await idb.getAllFromDB(elementName)
			.then((result) => {
				let resources = result;
				if (resources && resources.length) {
					debug.log(elementName + " have been got from iDB");
					debug.close();
					return elementName === 'timeline' ? resources[0] : resources;
				} else {
					debug.log("There are no " + elementName + " in the iDB");
					debug.close();
					return null;
				}
			});
	},

	/**
	 *
	 * @param rootState
	 * @param commit
	 * @param dispatch
	 * @param museum
	 * @returns {Promise<void>}
	 */
	processMuseum: async ({ rootState, commit, dispatch }, museum) => {
		debug.open("processMuseum");
		debug.table(museum);
		museum.fromDB = true;
		museum.resourcesFromDB = [];
		localStorage.setItem('current-museum', museum.idMuseum);

		for (let resource of museumResources) {
			let resourceName = resource;
			let resources = await dispatch('getElementsFromDB', resourceName);
			debug.log(resourceName + " in iDB: " + resources?.length + " - " + resourceName + " in museum: " + museum[resourceName].length);
			if ((resources?.length && resources.length === museum[resourceName].length)
				|| (resourceName === 'timeline' && resources && museum[resourceName])) {
				// If there are the same elements in the museum "raw data" than in the iDB,
				// it is everything OK
				debug.log("There is the correct number of " + resourceName + " in the iDB");
				museum[resourceName] = resources;
				museum.resourcesFromDB.push(resourceName);
			} else {
				// If there are different number of elements in the museum "raw data" and in the iDB,
				// then the iDB is incomplete and its data is not valid
				debug.log("There is a different number of " + resourceName + " in the iDB than in the museum, deleting them for reloading...");
				await idb.deleteAllInDB(resource);
				if (!rootState.app.isNetworkOnline) {
					debug.log("Saving " + resourceName + " again");
					let resource = museum[resourceName];
					await dispatch('saveElement', { element: (resourceName === 'timeline') ? [resource] : resource, elementName: resourceName });
					if (process.env.VUE_APP_DEBUG_MODE == "true") navigator?.storage?.estimate().then((data) => console.log("Storage data after saving " + resourceName + ": ", data));
				}
			}
		}

		commit(mutationTypes.SET_MUSEUM, museum);
		debug.close();
	},

	/**
	 * Fetch data for a given Museum
	 * @param store
	 * @param {(number|string)} idMuseum - The id of the museum
	 * @returns {Promise<void>}
	 */
	fetchMuseum: async ({ state, commit, dispatch }, idMuseum) => {
		return new Promise((resolve, reject) => {
			debug.open('fetchMuseum');
			console.log('fetchMuseum');

			dispatch('getVisit', idMuseum);

			if (!state.museum) {
				dispatch('getMuseumFromDB', idMuseum)
					.then(async (result) => {
						await dispatch('processMuseum', result);
						debug.close();
						resolve();
					})

					.catch(() => {
						debug.log("Museum " + idMuseum + " is not in iDB");

						MuseumsAPI.getMuseum(idMuseum)
							.then(async (response) => {
								if (response.data.success) {
									let museum = response.data.data;
									localStorage.setItem('forceDownloadAll', response.data.data?.forceDownloadAll ?? true );
									debug.open("Got museum " + idMuseum + " from API");
									debug.table(museum);
									debug.close();

									if (museum?.googleTagManagerId) {
										debug.log("Init Google Tag Manager with ID " + museum.googleTagManagerId);
										(function (w, d, s, l, i) {
											w[l] = w[l] || [];
											w[l].push({
												'gtm.start': new Date().getTime(),
												event: 'gtm.js'
											});
											let f = d.getElementsByTagName(s)[0],
												j = d.createElement(s),
												dl = l != 'dataLayer' ? '&l=' + l : '';
											j.async = true;
											j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
											f.parentNode.insertBefore(j, f);
										})(window, document, 'script', 'dataLayer', museum.googleTagManagerId);
									} else {
										debug.log("Could not init Google Tag Manager because there were no GTM ID");
									}

									response.data.data.isModel = true;
									localStorage.setItem('current-museum', museum.idMuseum);
									commit(mutationTypes.SET_MUSEUM, museum);
									debug.close();

									CustomizationsAPI.getCustomizationByMuseum(idMuseum)
										.then((response) => {
											debug.open("Got museum customization for museum " + idMuseum + " from API");
											debug.table(response.data.data[0]);
											debug.close();
											commit(mutationTypes.SET_CUSTOMIZATION, response.data.data[0]);
											idb.saveToDB(response.data.data[0], "customizationsPresentation");
											resolve();
										})
										.catch((error) => {
											if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(error);
											reject();
										});

								} else {
									debug.log("Error getting museum  " + idMuseum + " from API", response.data.data);
									// commit(mutationTypes.SET_MUSEUM_FETCHING_ERROR, true);
									debug.close();
									reject();
								}
							})

							.catch((error) => {
								if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(error);
								// commit(mutationTypes.SET_MUSEUM_FETCHING_ERROR, true);
								debug.close();
								reject();
							});
					});
			} else {
				debug.log("Museum was already in memory");
				debug.close();
				resolve();
			}
		});
	},

	/**
	 * Gets or creates a visit used for statistics
	 * @param rootState
	 * @param commit
	 * @param {number} idMuseum - The id of the museum
	 */
	getVisit: async ({ commit }, idMuseum) => {
		debug.open('getVisit');
		const localVersion = localStorage.getItem('version');
		if (localVersion !== process.env.VUE_APP_VERSION)
			localStorage.removeItem('visit');
		let visit = new Visit();
		let visitFromLocalStorage = localStorage.getItem('visit');
		if (!visitFromLocalStorage || visitFromLocalStorage === "undefined") {
			debug.log("There is no visit ongoing, creating one...");
			localStorage.setItem("lastVisitV2", new Date());
			visit = await createNewVisit(idMuseum);
		} else {
			visit.parseJSON(JSON.parse(visitFromLocalStorage));
			debug.log("There is a visit ongoing: ", visit);

			if (compareDatesToday(visit.startDate)) {
				debug.log("There was a visit ongoing, but it is old, creating a new one...");
				localStorage.setItem("lastVisitV2", visit.startDate);
				visit = await createNewVisit(idMuseum);
			}
		}

		visit.endDate = new Date();
		MuseumsAPI.registerVisit(idMuseum, visit)
			.then((response) => {
				visit.idVisit = response.data?.data?.IdVisit;
				debug.close();
			})
			.catch((e) => {
				if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
			})
			.finally(() => {
				commit('app/' + mutationTypes.SET_VISIT, visit, { root: true });
				localStorage.setItem('visit', JSON.stringify(visit));
			});
	},

	/**
	 * Fetch full data for a given Museum
	 * @param store
	 * @param {(number|string)} idMuseum - The id of the museum
	 * @returns {Promise<void>}
	 */
	fetchMuseumDetail: async ({ rootState, state, commit, dispatch }, idMuseum) => {
		return new Promise((resolve, reject) => {
			debug.open('fetchMuseumDetail');
			dispatch('getVisit', idMuseum);

			if (state) {
				dispatch('getMuseumFromDB', idMuseum)
				.then((result) => {
					try {
						dispatch('processMuseum', result)
							.then(() => {
								debug.close();
								resolve();
							})
							.catch((error) => {
								debug.log(`Error al procesar el museo: ${error.message || error}`);
								reject(error);
							});
					} catch (error) {
						debug.log(`Error inesperado al intentar procesar el museo: ${error.message || error}`);
						reject(error);
					}
				})
					.catch(async () => {
						debug.log("Museum " + idMuseum + " is not in iDB.");
						await MuseumsAPI.getMuseumDetail(idMuseum)
							.then(async (response) => {
								if (response.data.success) {
									localStorage.setItem('slides-seen', response.data.data?.slides[0]?.active ?? false);
			
									debug.open("Got museum " + idMuseum + " details from API");
									debug.table(response.data.data);

									debug.close();

									let details = response.data.data;
									details.locale = findLocalization(details);
									debug.log("Museum " + details.idMuseum + " locale " + (details.locale ? "found" : "not found"));

									if (!rootState.app.isNetworkOnline) {
										await dispatch('save', { museum: details, download: rootState.app.resourcesOutOfDB });
									} else {
										details.resolvedResources = true;
									}

									localStorage.setItem('current-museum', details.idMuseum);
									commit(mutationTypes.SET_MUSEUM, details);

									let sideMenu = state.museum.sideMenuCustomization;
									if (sideMenu) {
										debug.open("Got side menu for museum " + idMuseum);
										sideMenu.locale = findLocalization(sideMenu);
										debug.log("Side menu " + sideMenu.idSideMenuCustomization + " locale " + (sideMenu.locale ? "found" : "not found"));
										commit(mutationTypes.SET_SIDE_MENU, sideMenu);
										debug.close();
									} else {
										debug.open("There is no side menu for museum " + idMuseum);
									}

									debug.close();
									resolve();
								} else {
									debug.log("Error getting museum  " + idMuseum + " details from API", response.data.data);

									commit(mutationTypes.SET_MUSEUM_FETCHING_ERROR, true);
									debug.close();
									reject();
								}
							})

							.catch((error) => {
								if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(error);
								commit(mutationTypes.SET_MUSEUM_FETCHING_ERROR, true);
								debug.close();
								if (error.response?.status === 1000) {
									router.push({ name: 'NotFound' });
								} else {
									reject();
								}
							});
					});
			} else {
				debug.log("Museum was already in memory");
				debug.close();
				resolve();
			}
		});
	},

	changeSideMenuLocale({ state, commit }) {
		let sideMenu = state.museum.sideMenuCustomization;
		if (sideMenu) {
			debug.open("Changing side menu locale to " + i18n.locale);
			sideMenu.locale = findLocalization(sideMenu);
			debug.log("Side menu " + sideMenu.idSideMenuCustomization + " locale " + (sideMenu.locale ? "found" : "not found"));
			commit(mutationTypes.SET_SIDE_MENU, sideMenu);
			debug.close();
		}
	},

	deleteElementFromIDB: async (store, { elementID, storeName }) => {
		debug.open("deleteDownload");
		if (!elementID) {
			await idb.deleteAllInDB(storeName);
			debug.log("Deleted the table " + storeName);
		} else {
			await idb.deleteFromDB(elementID, storeName);
			debug.log("Deleted the element from the table " + storeName, elementID);
		}
		debug.close();
	},

	getDownloads: async ({ state, commit, dispatch }) => {
		debug.open("getDownloads");
		commit(mutationTypes.SET_DOWNLOADS_ERROR, false);

		if (state.museum) {
			let routes = [];
			let exhibitions = [];
			let timelines = [];
			let extras = [
				"oneMinutes",
				"infoSections",
				"halls",
				"navigationSections",
				"sections",
			];
			let downloaded = {
				routes: {},
				exhibitions: {},
				timelines: {},
				extras: {},
				navigationExtra: {}
			};

			for (const route of state.museum.routes) {
				if (state.museum.routes) {
					route.locale = findLocalization(route);
					route.artworkCount = 0;
					route.artworkIDs = [];
					for (const step of route.steps) {
						if (step.idArtwork) {
							route.artworkCount++;
							route.artworkIDs = route.artworkIDs.concat([step.idArtwork]);
						}
					}

					if (route.isExhibition) {
						exhibitions.push(route);
					} else {
						routes.push(route);
					}
				}
			}

			if (state.museum.timeline) {
				let timeline = state.museum.timeline;
				timelines.push(await dispatch('timeline/processTimeline', timeline, { root: true }));
			}
			extras = extras.filter(extra => state.museum[extra].length);
			for (const extra of extras) {
				let storedExtra = await idb.getAllFromDB(extra);
				if (storedExtra.length === state.museum[extra].length) {
					downloaded.extras[extra] = true;
				}
			}

			let navigationExtra = state.museum.navigationSections;
			downloaded.navigationExtra = navigationExtra;
			let downloads = new Downloads(routes, exhibitions, timelines, extras, downloaded, navigationExtra);
			debug.open("Found this things to download:");
			debug.log("Routes: ", downloads.routes.length);
			debug.log("Timeline: ", downloads.timelines.length);
			debug.log("Extras: ", downloads.extras);
			debug.close();
			commit(mutationTypes.SET_DOWNLOADS, downloads);
		} else {
			debug.log("There is no museum found");
			commit(mutationTypes.SET_DOWNLOADS_ERROR, true);
		}
		debug.close();
	}
};

const mutations = {
	[mutationTypes.SET_MUSEUM]: (state, value) => state.museum = value,
	[mutationTypes.SET_CUSTOMIZATION]: (state, value) => state.customization = value,
	[mutationTypes.SET_SIDE_MENU]: (state, value) => state.sideMenu = value,
	[mutationTypes.SET_MUSEUM_FETCHING_ERROR]: (state, value) => state.museumFetchingError = value,
	[mutationTypes.SET_SAVED_RESOURCES]: (state, value) => state.savedResources = value,
	[mutationTypes.SET_SAVING_RESOURCE]: (state, value) => state.savingResource = value,
	[mutationTypes.SET_TOTAL_RESOURCES]: (state, value) => state.totalResources = value,
	[mutationTypes.SET_DOWNLOADS]: (state, value) => state.downloads = value,
	[mutationTypes.SET_DOWNLOADS_ERROR]: (state, value) => state.downloadsError = value,
	[mutationTypes.ADD_DOWNLOADS_PROGRESS]: (state, value) => {
		let currentValue = state.downloads.inProgress[value.store][value.element] || 0;
		state.downloads.inProgress[value.store] = { ...state.downloads.inProgress[value.store], [value.element]: currentValue + 1 };
	},
	[mutationTypes.DELETE_DOWNLOADS_PROGRESS]: (state, value) => {
		state.downloads.inProgress[value.store] = { ...state.downloads.inProgress[value.store], [value.element]: 0 };
		state.downloads.downloaded[value.store] = { ...state.downloads.downloaded[value.store], [value.element]: true };
	},
	[mutationTypes.DELETE_DOWNLOAD]: (state, value) => {
		state.downloads.downloaded[value.store] = { ...state.downloads.downloaded[value.store], [value.element]: false };
	},
};

export default {
	moduleName: 'museum',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};


