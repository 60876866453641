import { debug } from "@/misc/debug";
import i18n from "@/i18n";
import idb from "@/api/base/idb";
import Fingerprint from "fingerprintjs";
import { axiosv2 } from "../api/base/API";
import Visit from "@/models/visit";
const museumResources = ['artworks', 'halls', "infoSections", "navigationSections", "oneMinutes", "routes", "sections", "timeline"];
export { museumResources };

/**
 * Gets the user's locale localization for an element (first localization of the element if fallbackLocale is true
 * @param element {*} An object with multiple localizations
 * @param fallbackLocale {boolean}  Indicates if it should be used the first found localization in the element instead
 *                                  of the user's locale
 * @returns {*} The object containing the localization of the element, null if the element has no localizations
 */
function findLocalization(element, fallbackLocale = false) {
	debug.open("findLocalization");
	let localization = null;

	if (element.hasOwnProperty('localizations')) {
		if (Array.isArray(element.localizations) && element.localizations.length) {
			localization = element.localizations.find(localization => {
				let cleanLocalization = localization.languageCode.replace(/[|&;$%@"<>_\-()+,]/g, "");
				let shortCleanLocalization = cleanLocalization.substr(0, 2);
				let cleanLocale = fallbackLocale ? cleanLocalization : i18n.locale.replace(/[|&;$%@"<>_\-()+,]/g, "");

				return cleanLocale === cleanLocalization || cleanLocale === shortCleanLocalization;
			});
		} else {
			debug.log("This element has no localizations", element);
			return {};
		}

		if (!localization) {
			debug.log("Not found localization for " + i18n.locale, element);
			localization = findLocalization(element, true);
			debug.log("Fallback localization " + (localization ? "got instead" : "not found"), element);
		}
	}

	debug.log((localization ? "Got" : "Not found") + " localization for " + i18n.locale, element);
	debug.close();
	return localization;
}
export { findLocalization };

/**
 * Gets the extra resources for the artwork indicated
 * @param resources {array} An array of artwork extra resources
 * @param locale {string} Indicates the code of the language it should search
 */
function findResourcesByLocale(resources, locale, sideMenuLocale) {
	let localizedResources = [];
	if (resources.length) {
		for (let index in resources) {
			let resource = resources[index];
			if (resource.hasOwnProperty('localizations')) {
				for (let localization of resource.localizations) {
					let cleanLocalization = localization.languageCode.replace(/[|&;$%@"<>_\-()+,]/g, "");
					let shortCleanLocalization = cleanLocalization.substr(0, 2);
					let cleanLocale = locale.replace(/[|&;$%@"<>_\-()+,]/g, "");
					const shortSideMenuLocaole = sideMenuLocale.replace(/[|&;$%@"<>_\-()+,]/g, "");

					if (cleanLocale === cleanLocalization || cleanLocale === shortCleanLocalization || cleanLocalization === shortSideMenuLocaole) {
						resource.locale = findLocalization(resource);
						localizedResources.push(resource);
					}
				}
			} else {
				localizedResources.push(resource);
			}
		}
	}
	return localizedResources;
}
export { findResourcesByLocale };

async function getArtworkImage(museum, idArtwork) {
	let artworkImage = null;
	let artwork = null;

	if (museum && idArtwork) {
		artwork = await idb.getFromDB(parseInt(idArtwork), 'artworks');
		if (!artwork) {
			artwork = museum.artworks.find(artwork => artwork.idArtwork === parseInt(idArtwork));
		}
	}

	if (artwork) {
		const mainImageUrl = artwork.mainImageUrl;
		if (mainImageUrl instanceof Blob) {
			artworkImage = await blobToUrl(mainImageUrl);
		} else if (typeof mainImageUrl === 'string') {
			artworkImage = mainImageUrl;
		}

	}

	return artworkImage;
}

/**
 * Convierte un Blob a una URL válida.
 * @param blob {Blob} El Blob a convertir.
 * @returns {Promise<string>} La URL válida del Blob.
 */
function blobToUrl(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const url = URL.createObjectURL(blob);
            resolve(url);
        };

        reader.onerror = (error) => {
            reject('reader.onerror: ' + error);
        };

        reader.readAsDataURL(blob);
    });
}
export {blobToUrl};

/**
 * Convierte una URL a Blob
 * */ 
function urlToBlob(url) {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                resolve(blob);
            })
            .catch(error => {
                reject('Error fetching the URL: ' + error);
            });
    });
}

export { urlToBlob };


export { getArtworkImage };

async function getArtworkAudio(museum, idArtwork) {
	let artwork = null;
	let artworkAudio = null;

	if (museum && idArtwork) {
		artwork = await idb.getFromDB(parseInt(idArtwork), 'artworks');
		if (!artwork) {
			artwork = museum.artworks.find(artwork => artwork.idArtwork === parseInt(idArtwork));
		}
	}

	if (artwork) {
		artwork.locale = findLocalization(artwork);
		if (artwork.locale) {
			artworkAudio = artwork.locale.mainAudioUrl;
		}
	}

	return artworkAudio;
}
export { getArtworkAudio };

async function getArtworkInfo(museum, idArtwork) {
	let artwork = null;
	let artworkCode = null;
	let artworkName = null;
	let isOutstanding = false;
	let showCode = true;

	if (museum && idArtwork) {
		artwork = await idb.getFromDB(parseInt(idArtwork), 'artworks');
		if (!artwork) {
			artwork = museum.artworks.find(artwork => artwork.idArtwork === parseInt(idArtwork));
		}
	}

	if (artwork) {
		artworkCode = artwork.code;
		isOutstanding = artwork.outstanding;
		showCode = artwork.showCode;
		artwork.locale = findLocalization(artwork);
		if (artwork.locale) {
			artworkName = artwork.locale.name;
		}
	}

	return { artworkCode, artworkName, isOutstanding, showCode };
}
export { getArtworkInfo };

async function getArtworkResources(museum, idArtwork) {
	let artwork = null;

	if (museum && idArtwork) {
		artwork = await idb.getFromDB(parseInt(idArtwork), 'artworks');
		if (!artwork) {
			artwork = museum.artworks.find(artwork => artwork.idArtwork === parseInt(idArtwork));
		}
	}

	let resources = (artwork)
		? findResourcesByLocale(artwork.otherResources, i18n.locale)
		: [];

	for (let resource of resources) {
		resource.locale = findLocalization(resource);
	}

	return resources;
}
export { getArtworkResources };

function setFullScreen() {
	if (process.env.VUE_APP_DEBUG_MODE == "true" || window.canLog) {
		console.groupCollapsed("setFullScreen");
		console.warn("You are in debug mode. Now the app should have been put on fullscreen, but in debug mode that behaviour is avoided");
	} else {
		let method = document.body.requestFullScreen
			|| document.body.webkitRequestFullScreen
			|| document.body.mozRequestFullScreen
			|| document.body.msRequestFullScreen;

		if (method) {
			method.call(document.body);
		} else if (typeof window.ActiveXObject !== "undefined") {
			// eslint-disable-next-line no-undef
			let wScript = new ActiveXObject("WScript.Shell");

			if (wScript !== null) {
				wScript.SendKeys("{F11}");
			}
		}
	}
	debug.close();
}

export { setFullScreen };

function createNewVisit(idMuseum) {
	let visitorToken = (new Date()).toISOString();
	let idDevice = 'EG' + new Fingerprint().get();
	let languageFinded = i18n.locale.replace(/([^\w ]|_)/g, '').toLowerCase();
	return axiosv2.get(`languages/${idMuseum}`)
		.then((response) => {
			const arrayLanguagesMuseum = response.data;
			if (arrayLanguagesMuseum.length === 0) {
				localStorage.setItem('languagesV2', JSON.stringify(arrayLanguagesMuseum));
				return new Visit(visitorToken, idDevice, new Date(), 'es_ES', idMuseum, null);
			}
			const found = arrayLanguagesMuseum.find(e => e.code.replace(/([^\w ]|_)/g, '').toLowerCase() === languageFinded);

			if (!found) {
				languageFinded = response.data[0].code;
			} else {
				languageFinded = found.code;
			}
			localStorage.setItem('languagesV2', JSON.stringify(arrayLanguagesMuseum));
			return new Visit(visitorToken, idDevice, new Date(), languageFinded, idMuseum, null);
		})
		.catch((error) => {
			console.log('error', error);
		});
}

export { createNewVisit };
