import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

/**
 * Get all the json files in the /src/locales and creates the i18n message
 * @returns {{}} The i18n object with locales and its translations
 */
function loadLocaleMessages () {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}

function getLanguageStoragev2(){
	const language = JSON.parse(localStorage.getItem('languagesV2'));
	const visitv2 = JSON.parse(localStorage.getItem('visitv2'));
	const response = language?.find(e => e.id === visitv2?.idLanguage)?.code ; 
	return response || null;
} 

export default new VueI18n({
	/*
	 * "navigator.language" will cause a fallback warning in the JS console due to "navigator.language"
	 * being i.e. 'es-ES' and fall backing into 'es'.
	 * See https://kazupon.github.io/vue-i18n/guide/fallback.html#implicit-fallback-using-locales for details
	 */
	locale:  localStorage.getItem('language') || getLanguageStoragev2() || navigator.language || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: [process.env.VUE_APP_I18N_FALLBACK_LOCALE, 'en', 'es'],
	formatFallbackMessages: true, // See details of the fallback interpolation here https://kazupon.github.io/vue-i18n/guide/fallback.html#fallback-interpolation
	messages: loadLocaleMessages(),
	silentFallbackWarn: true
});
