import { render, staticRenderFns } from "./Slides.vue?vue&type=template&id=14aefe7e&scoped=true"
import script from "./Slides.vue?vue&type=script&lang=js"
export * from "./Slides.vue?vue&type=script&lang=js"
import style0 from "./Slides.vue?vue&type=style&index=0&id=14aefe7e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14aefe7e",
  null
  
)

export default component.exports